import request from '@/util/request'
export default {
    // 申请名企
    setDachang(query) {
        return request({
            url: "/api/enterprise/appFamous",
            method: "post",
            data: { ...query }
        })
    },
    // 查看申请状态
    getDachang() {
        return request({
            url: "/api/enterprise/getFamous",
            method: 'post'
        })
    },
    // 获取企业详情
    releasePosition(query) {
        return request({
            url: "/api/enterprise/detail",
            method: "post",
            data: { ...query }
        })
    },
    // 获取企业列表
    getEnterpriseList(query) {
        return request({
            url: "/api/enterprise/getList",
            method: "post",
            data: { ...query }
        })
    },
    // 获取企业已发布的职位列表
    simpleList(query) {
        return request({
            url: "/api/position.index/simpleList",
            method: "post",
            data: { ...query }
        })
    },
    // 人才推送
    perPush(query) {
        return request({
            url: "/api/position.push/personPush",
            method: "post",
            data: { ...query }
        })
    },
    // 获取人才推送的数量
    getPushNum(query) {
        return request({
            url: "/api/position.push/getPushPersonTotal",
            method: "post",
            data: { ...query }
        })
    },
    // 设置人才沟通状态
    setLineStatue(query) {
        return request({
            url: "/api/position.push/setLinkStatus",
            method: "post",
            data: { ...query }
        })
    },
    // 获取当前用户是否被设置为不合适了
    getLinkStatus(query) {
        return request({
            url: "/api/position.push/getLinkStatus",
            method: "post",
            data: { ...query }
        })
    },
    // 收藏的列表
    myCollection() {
        return request({
            url: "/api/position.push/myCollection",
            method: "post"
        })
    },
    // (取消)收藏人才
    collectionPerson(query) {
        return request({
            url: "/api/position.push/collectionPerson",
            method: "post",
            data: { ...query }
        })
    },
    // 订单列表
    orderList(query) {
        return request({
            url: "/api/enterprise.order/lists",
            method: "post",
            data: { ...query }
        })
    },
    // 收支明细
    accountLogs(query) {
        return request({
            url: "/api/user/accountLogs",
            method: "post",
            data: { ...query }
        })
    },
    //获取专属客服
    getKefu() {
        return request({
            url: "/api/enterprise/getKefu",
            method: "post"
        })
    },
    // 申请发票
    addInvoice(query) {
        return request({
            url: "/api/enterprise/addInvoice",
            method: "post",
            data: { ...query }
        })
    }
}