<template>
  <!-- 弹窗组件 -->
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 1000"
        @click.stop="hide"
      ></div>
      <div
        class="mask-company animated faster fadeIn"
        :style="{ width: width, padding: padding }"
      >
        <div class="row-end">
          <i class="el-icon-close pointer" v-if="colseShow" @click="hide"></i>
        </div>
        <div class="text-main row-center img-title" v-if="titleShow">
          <img src="../assets/glxt/21.png" alt="" v-if="imgShow" />
          <span>{{ title }}</span>
        </div>
        <slot></slot>
        <div class="row-center mask-btn">
          <div
            class="text-blue flex-center pointer border-first"
            v-if="btnCancel"
            @click="hide"
          >
            取消
          </div>
          <div
            class="text-blue flex-center pointer border-first"
            v-if="rejectBtn"
            @click="rejectClick"
          >
            拒绝
          </div>
          <div
            class="text-white bg-blue flex-center pointer"
            @click="submitClick"
            v-if="btnSubShow"
          >
            {{ sureContent }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/user";
export default {
  props: {
    interview: {
      type: Boolean,
      default: true,
    },
    btnCancel: {
      type: Boolean,
      default: true,
    },
    colseShow: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "提示！",
    },
    number: {
      type: Number,
    },
    width: {
      type: String,
    },
    sureContent: {
      type: String,
      default: "确定",
    },
    padding: {
      type: String,
    },
    titleShow: {
      type: Boolean,
      default: true,
    },
    imgShow: {
      type: Boolean,
      default: false,
    },
    btnSubShow: {
      type: Boolean,
      default: true,
    },
    rejectBtn: {
      type: Boolean,
      default: false,
    },
    recruiter:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      status: false,
    };
  },
  methods: {
    // 显示
    show() {
      this.status = true;
    },
    // 隐藏
    hide() {
      if(!this.recruiter){
        this.$emit("submitClick");
        return
      }
      if (this.number == 1) {
        api.toggleUser({ user_type: 1 }).then((res) => {
          this.$router.push({ path: "/recruiter" });
        });
      } else if (this.number == 2) {
        api.toggleUser({ user_type: 1 }).then((res) => {
          this.$router.push({ name: "Home" });
        });
      }
      this.status = false;
      this.$emit("hide");
    },
    // 确定
    submitClick() {
      if (this.interview) {
        this.hide();
      }
      this.$emit("submitClick");
    },
    // 拒绝
    rejectClick() {
      this.$emit("rejectClick");
      this.hide();
    },
  },
};
</script>
<style lang="less" scoped>
.mask-company {
  width: 46.8125rem;
  background: #ffffff;
  border-radius: 3px;
  padding: 1rem 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  .img-title img {
    width: 21px;
    height: 19px;
    margin-right: 10px;
  }
  .mask-btn {
    justify-content: flex-end;
    div {
      padding: 6px 30px;
      margin-left: 0.875rem;
      font-size: 0.875rem;
    }
    .border-first {
      border: 1px solid #126bf9;
    }
  }
}
</style>
