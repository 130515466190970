<template>
	<!-- 添加企业地址组件 -->
	<div>
		<div v-if="status">
			<div class="position-fixed top-0 left-0 right-0 bottom-0"
				style="background-color: rgba(0, 0, 0, 0.5); z-index: 99" @click.stop="hide"></div>
			<div class="position-absolute address-add-view">
				<div class="row-end">
					<i class="el-icon-close pointer" @click="hide"></i>
				</div>
				<div class="add-text text-main">{{ title }}</div>
				<div class="add-text-one">请填写真实地址，若查实造假，账号将被冻结。</div>
				<div class="row-center add-selet">
					<div class="text-main">工作城市：</div>
					<div class="select-div">
						<el-cascader size="small" :props="{ value: 'id' }" :options="addressList" placeholder="请选择城市"
							class="flex_inputs" @change="handleChange" v-model="addressValue" ref="addressCascader">
						</el-cascader>
					</div>
				</div>
				<div class="row-center add-selet">
					<div class="text-main">工作地点：</div>
					<div class="select-div">
						<el-input type="textarea" placeholder="请输入详细的工作地点" :autosize="{ minRows: 2, maxRows: 2}" v-model="query.address">
						</el-input>
					</div>
				</div>
				<div class="row-end add-submit">
					<div class="text-white bg-blue pointer" :class="[addressValue.length === 0 ? '' : 'submit-button']"
						@click="submitClick">
						保存
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import toolApi from "../api/tool";
	import api from "../api/address";
	export default {
		components: {},
		props: {
			title: {
				type: String,
				default: "添加地址",
			},
			itemAddress: {
				type: Object,
			},
		},
		data() {
			return {
				status: false,
				addressList: [],
				addressValue: [],
				query: {
					id: 0,
					province_id: "",
					province: "",
					city_id: "",
					city: "",
					address: "",
				},
			};
		},

		created() {
			this.addressClick();
		},
		watch: {
			itemAddress(val) {
				this.query = val;
				this.addressValue.push(Number(val.province_id));
				this.addressValue.push(Number(val.city_id));
			},
		},
		mounted() {},

		methods: {
			handleChange(e) {
				this.query.province_id = e[0];
				this.query.city_id = e[1];
				const obj = this.$refs.addressCascader.getCheckedNodes();
				this.query.province = obj[0].pathNodes[0].label;
				this.query.city = obj[0].label;
			},
			// 获取省市区
			addressClick() {
				toolApi.getAddress().then((res) => {
					let region = res.data.children;
					region.forEach((element) => {
						element.children.forEach((item) => {
							item.children = "";
						});
					});
					this.addressList = region;
				});
			},
			// 隐藏
			hide() {
				this.query.address = ''
				this.addressValue = [];
				this.status = false;
				this.$emit("hide");
			},
			// 显示
			show() {
				this.status = true;
			},
			// 保存
			submitClick() {
				if (this.addressValue.length === 0) {
					this.$util.msg("请选择地址", "warning");
					return;
				}
				if (this.query.address == "") {
					this.$util.msg("请填写详细地址", "warning");
					return;
				}
				api.setAddress(this.query).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg);
						this.$util.objClear(this.query);
						this.hide();
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.address-add-view {
		width: 30.125rem;
		background: #ffffff;
		border-radius: 3px;
		z-index: 100;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 1.375rem 1.375rem 2.1875rem 1.9375rem;

		.add-text {
			font-size: 1.125rem;
			margin-bottom: 1rem;
		}

		.add-text-one {
			color: #9fa3b0;
			font-size: 0.875rem;
			margin-bottom: 3.125rem;
		}

		.add-selet {
			margin-bottom: 1.25rem;
			font-size: 0.875rem;

			.select-div {
				margin-left: 1.25rem;
				width: 18.125rem;

				/deep/ .el-select {
					width: 100%;
				}
			}
		}

		.add-input {
			margin-bottom: 1.875rem;
			font-size: 0.875rem;

			>div:first-child {
				padding-top: 0.6875rem;
			}

			>div:last-child {
				margin-left: 1.25rem;

				/deep/.el-textarea {
					width: 290px;
					height: 90px;
				}

				/deep/ .el-textarea__inner {
					min-height: 90px !important;
				}
			}
		}

		.add-submit {
			div {
				width: 110px;
				height: 34px;
				opacity: 0.5;
				font-size: 0.875rem;
				line-height: 34px;
				text-align: center;
			}

			.submit-button {
				opacity: 1;
			}
		}
	}
</style>
