import request from '@/util/request'

export default {
    // 短信验证码登录
    smsLogin(query){
        return request({
            url:"/api/user/codeLogin",
            method:'post',
            data:{...query}
        })
    },
    // 密码登录
    pwdLogin(query){
        return request({
            url:"/api/user/pwdLogin",
            method:'post',
            data:{...query}
        })
    },
    // 获取短信验证码
    getSms(query){
        return request({
            url:"/api/user/sendSms",
            method:"post",
            data:{...query}
        })
    },
    // 获取用户信息
    userDetail(){
        return request({
            url:"/api/user/detail",
            method:"post"
        })
    },
    // 获取腾讯IM userSig
    getUserSig(query){
        return request({
            url:"/api/user/getUserSig",
            method:"post",
            data:{...query}
        })
    },
    // 用户隐私设置
    setPrivate(query){
        return request({
            url:"/api/user/setPrivate",
            method:"post",
            data:{...query}
        })
    },
    // 获取用户隐私设置
    getPrivate(){
        return request({
            url:"/api/user/getPrivate",
            method:"post"
        })
    },
	// 更新非表中企业
	updateWorkCompany(query){
	    return request({
	        url:"/api/user/updateWorkCompany",
	        method:"post",
			data:{...query}
	    })
	},
    // 搜索企业进行屏蔽
    searchList(query){
        return request({
            url:"/api/enterprise/getList",
            method:"post",
            data:{...query}
        })
    },
    // 设置简历信息
    setResume(query){
        return request({
            url:"/api/user/setResume",
            method:"post",
            data:{...query}
        })
    },
    // 获取简历信息
    getResume(query={}){
        return request({
            url:"/api/user/getResume",
            method:"post",
            data:{...query}
        })
    },
    // 切换身份
    toggleUser(query){
        return request({
            url:"/api/user/changeIdentity",
            method:"post",
            data:{...query}
        })
    },
    // 解绑手机号码
    unBindPhone(query){
        return request({
            url:"/api/user/unBindPhone",
            method:"post",
            data:{...query}
        })
    },
    // 绑定手机号码
    bindPhone(query){
        return request({
            url:"/api/user/bindPhone",
            method:"post",
            data:{...query}
        })
    },
    // 发送邮箱验证吗
    emailCode(query){
        return request({
            url:"/api/user/sendEmail",
            method:"post",
            data:{...query}
        })
    },
    // 绑定邮箱
    bindEmail(query){
        return request({
            url:"/api/user/bindEmail",
            method:"post",
            data:{...query}
        })
    },
    // 解绑邮箱
    unBindEmail(query){
        return request({
            url:"/api/user/unBindEmail",
            method:"post",
            data:{...query}
        })
    },
    // 设置密码
    setPassword(query){
        return request({
            url:"/api/user/setPassword",
            method:"post",
            data:{...query}
        })
    },
    // 设置用户基本信息
    setUserBasic(query){
        return request({
            url:"/api/user/setUserSimple",
            method:'post',
            data:{...query}
        })
    },
    // 设置不合适
    setOutStatus(query){
        return request({
            url:"/api/enterprise/setOutStatus",
            method:"post",
            data:{...query}
        })
    },
    // 发送面试邀约
    sendInterView(query){
        return request({
            url:"/api/enterprise/interview",
            method:"post",
            data:{...query}
        })
    },
    // 接受拒绝面试邀约
    setStateInterview(query){
        return request({
            url:"/api/user/setStateInterview",
            method:"post",
            data:{...query}
        })
    },
	// 获取全部面试邀约
	interviewList(query){
	    return request({
	        url:"/api/user/interviewList",
	        method:"post",
	        data:{...query}
	    })
	},
    // 账户资产
    userAccount(){
        return request({
            url:"/api/user/account",
            method:"post"
        })
    },
    // 购买服务
    buyService(query){
        return request({
            url:"/api/user/buyService",
            method:"post",
            data:{...query}
        })
    },
    // 纺职豆兑换其它服务
    exChangeService(query){
        return request({
            url:"/api/user/exchangeService",
            method:"post",
            data:{...query}
        })
    },
    // 获取平台服务设置
    getPlatFrom(){
       return request({
        url:"/api/common/getPlatForm",
        method:'get'
       })
    },
    // 升级vip
    buyVip(query){
        return request({
            url:"/api/user/buyVip",
            method:"post",
            data:{...query}
        })
    },
    // 获取用户手机号码
    userPhone(query){
        return request({
            url:"/api/user/userPhone",
            method:"post",
            data:{...query}
        })
    },
    // 企业是否收藏该人才
    isCollectUser(query){
        return request({
            url:"/api/enterprise/isCollectUser",
            method:"post",
            data:{...query}
        })
    },
    // 获取个人职位相关的操作数量
    getPosiNum(){
        return request({
            url:"/api/user/getPositionNums",
            method:"post"
        })
    },
    //检测账户是否存在
    checkUser(query){
        return request({
            url:"/api/user/checkUser",
            method:"post",
            data:{...query}
        })
    },
    // 忘记密码发送验证码
    sendForgetCode(query){
        return request({
            url:"/api/user/sendForgetCode",
            method:"post",
            data:{...query}
        })
    },
    // 找回用户信息
    checkCodeUser(query){
        return request({
            url:"/api/user/checkCodeUser",
            method:"post",
            data:{...query}
        })
    },
    // 重置密码
    resetPassword(query){
        return request({
            url:"/api/user/resetPassword",
            method:"post",
            data:{...query}
        })
    },
    // 看看别人写的
    getAdvantages(){
        return request({
            url:"/api/user/getAdvantages",
            method:"post"
        })
    },
     // 更换（离开）企业
     leaveEnterprise(query){
        return request({
            url:"/api/user/leaveEnterprise",
            method:"post",
            data:{...query}
        })
     },
    //  微信扫码登录
    scanLogin(query){
        return request({
            url:"/api/user/scanLogin",
            method:"post",
            data:{...query}
        })
    },
    // 绑定微信
    bindWechat(query){
        return request({
            url:"/api/user/bindWechat",
            method:"post",
            data:{...query}
        })
    },
    // 解绑微信
    unBindWechat(query){
        return request({
            url:"/api/user/unBindWechat",
            method:"post",
            data:{...query}
        })
    },
    // 查询订单支付情况
    searchOrder(query){
        return request({
            url:"/api/user/searchOrder",
            method:"post",
            data:{...query}
        })
    },
    //获取个人认证的二维码
    getUserCertify(query){
        return request({
            url:"/api/user/getUserCertify",
            method:"post",
            data:{...query}
        })
    },
    // 查询支付宝个人认证
    queryCertify(){
        return request({
            url:"/api/user/queryCertify",
            method:"post"
        })
    },
    // 用户实际资产
    actualAccounts(query){
        return request({
            url:"/api/user/actualAccounts",
            method:"post",
            data:{...query}
        })
    },
	// 交换联系方式
	exchangePhone(query){
	    return request({
	        url:"/api/user/exchangePhone",
	        method:"post",
	        data:{...query}
	    })
	},
	// 更新交换联系方式
	upExchangePhone(query){
	    return request({
	        url:"/api/user/upExchangePhone",
	        method:"post",
	        data:{...query}
	    })
	},
	// 获取交换联系列表
	getExchangeList(query){
	    return request({
	        url:"/api/user/getExchangeList",
	        method:"post",
	        data:{...query}
	    })
	}
}