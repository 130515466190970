import request from '@/util/request'

export default {
    //设置企业信息
    recruiter(query) {
        return request({
            url: "/api/enterprise/setInfo",
            method: "post",
            data: { ...query }
        })
    },
    // 企业认证
    enterprise(query){
        return request({
            url:"/api/enterprise/certification",
            method:"post",
            data:{...query}
        })
    },
    // 获取企业认证的状态
    getEnterprise(){
        return request({
            url:"/api/enterprise/getCertification",
            method:"post"
        })
    },
    // 设置打招呼语
    setSay(query={}){
        return request({
            url:"/api/enterprise/setSay",
            method:"post",
            data:{...query}
        })
    },
    // 获取打招呼语
    getSay(query){
        return request({
            url:"/api/enterprise/getSays",
            method:'post',
            data:{...query}
        })
    },
    // 删除打招呼语
    deleteSay(query){
        return request({
            url:"/api/enterprise/delSay",
            method:"post",
            data:{...query}
        })
    },
    // 企业面试相关的数据
    getInterviewList(query={}){
        return request({
            url:"/api/enterprise/interviewList",
            method:"post",
            data:{...query}
        })
    },
    // 企业首页数据
    getHomeToal(){
        return request({
            url:"/api/enterprise/getHomeTotal",
            method:"post",
        })
    }
}