import request from '@/util/request'
export default {
    // 设置地址
    setAddress(query){
        return request({
            url:"/api/enterprise/setAddress",
            method:"post",
            data:{...query}
        })
    },
    // 删除地址
    delAddress(query){
        return request({
            url:"/api/enterprise/delAddress",
            method:"post",
            data:{...query}
        })
    },
    // 获取地址
    getAddress(query){
        return request({
            url:"/api/enterprise/getAddressList",
            method:"post",
            data:{...query}
        })
    }
}